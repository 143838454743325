#Movie .pairing-backdrop {
    top: -40px;
    height: 575px;
    width: 100%;
    object-fit: cover;
    position: absolute;
}

/*noinspection ALL*/
#Movie .backdrop-mask {
    height: 625px;
    width: 100%;
    position: absolute;
    top: -50px;

    transition: 0.75s;
    box-shadow: inset 0px -250px 30px -100px var(--main-background),
    inset 250px 0px 100px -100px var(--main-background),
    inset -250px 0px 100px -100px var(--main-background);
}

#Movie .backdrop-full {
    z-index: -1;
    /*width: 1200px;*/
    width: 100%;
}

#Movie .pairing-card {
    margin-top: 420px;
    position: relative;
    z-index: 1;
    color: white;

    left: 50%;
    transform: translateX(-50%);
}

#Movie #Pairings{
    width: 75%;

}

#Movie .movie-description {
    width: 470px;
    font-weight: normal;
}

#Movie .movie-poster {
    transition: 0.25s;
    border: .5px solid white;
    border-radius: 10px ;
    width: 150px;

}

#Movie .movie-poster:hover {
    border-color: var(--main-link-hover);

}

#Movie hr{
    color: var(--main-light);
}

#Movie a:hover{
    color: var(--main-link-hover);
}

#Movie .whole-card{
    max-width: 900px;
}

#Movie {
    position: relative;
    top : -50px;
    /*width: 100%;*/
    width: min(100%, 1200px);
}

@media (max-width: 1200px) {

    #Movie .pairing-description{
        width: unset;
    }

    #Movie .pairing-backdrop {
        top: -40px;
        /*height: 575px;*/


        width: 100%;

        object-fit: cover;
        position: absolute;
    }

    #Movie .backdrop-mask {
        /*height: 625px;*/
        /*width: 1200px;*/
        width: 100%;

        position: absolute;
        /*top : -625px;*/
        top: -50px;

    }

    #Movie .backdrop-full {
        z-index: -1;
        width: 100%;
    }

    #Movie .pairing-card{
    }

}

@media (max-width: 992px) {
    #Movie .movie-poster{
        width: 100px;

    }
}

@media (max-width: 768px) {
    #Movie .movie-description{
        width: unset;
    }

    #Movie .movie-poster {
        display: none;
    }


}

@media (max-width: 576px) {
    #Movie .backdrop-mask{
        opacity: .7;
    }
}