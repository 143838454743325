
.create-pairing #pairing-heading {
    color: #b2b0b0;
}

.create-pairing #pairing-description {
    color: #b2b0b0;
    background: #445566 ;
    border-color: #445566;
    width: 100%;
}

.create-pairing #user{
    width: 100%;
    color: #b2b0b0;
    background: #445566 ;
    border-color: #445566;
}

.create-pairing #pairing-description :focus {
    color: deeppink;
}

.create-pairing #pairing-icon-stack {
    color: #b2b0b0 ;
}

.create-pairing #pair-link-button {
    padding: 0;
    margin: 0;
    height: 150px;
    background : #ff9b9b;
    background: none;
    border: none;
}

.create-pairing #pair-link-button:hover {
    transform: scale(1.05);
    /*background: #f5bcbc;*/

}

@media (max-width: 576px) {
    .create-pairing .cards{
        flex-direction: column;
        gap: 10px;
    }

    .create-pairing #user{
        width: 100%;
    }
    .create-pairing #pairing-description{
        width: 100%;
    }

    .create-pairing #pairing-icon-stack{
        order: 2;
    }
}