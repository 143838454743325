*{
  font-family: 'Uncut Sans', sans-serif;
  font-weight: bold;
}

html {
  display: flex;
  flex-direction: column;
  background: #282c34;
  min-height: 100%;
}

body {
  background: #930a0a;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

#root {
  min-height: 100vh;
}

#APP-CONTAINER {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: var(--main-background);
}

#Main-Navbar {
  margin-bottom: 48px;
}

#Navbar-Content {
  background: rgba(20, 22, 24, 0.15);
  transition: .5s;
}

#Navbar-Content:hover {
  z-index: 1;
  background: var(--main-background);
}



#MainContent {
  position: relative;
  min-height: 100%;
}