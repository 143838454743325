.home .carousel-indicators {
    display: none;
    visibility: hidden;
}

.home section {
    color: var(--main-light);
}

.home hr{
    color: var(--main-light);
}

.home a{
    color: var(--main-light);
}

.home a:hover{
    color: var(--main-link-hover);
    /*transform: scale(1.05);*/
}