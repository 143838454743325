
#pairing .pairing-backdrop {
    top: -40px;
    height: 575px;
    width: 100%;
    object-fit: cover;
    position: absolute;
}

#pairing .backdrop-mask {
    height: 625px;
    width: 100%;
    position: absolute;
    top: -50px;

    transition: 0.75s;

    box-shadow: inset 0px -250px 30px -100px var(--main-background),
                inset 250px 0px 100px -100px var(--main-background),
                inset -250px 0px 100px -100px var(--main-background);
}

#pairing .backdrop-full {
    z-index: -1;
    width: 100%;

}

#pairing .pairing-card {
    margin-top: 420px;
    position: relative;
    z-index: 1;
    left: 150px;
    color: white;
    padding-left: 24px;
    padding-right: 24px;
}

#pairing .pairing-description {
    width: 470px;
    font-weight: normal;
    padding-top: 20px;
}

#pairing .ingredient-list{
    width: 50%;
}

#pairing .movie-poster {
    transition: 0.25s;
    border: .5px solid white;
    border-radius: 10px ;
    width: 150px;

}

#pairing .recipe-picture {
    transition: 0.25s;
    border: .5px solid white;
    border-radius: 10px;
    width: 200px;
}

#pairing #pair-card{
    gap: 1rem;
}

#pairing .movie-poster:hover {
    border-color: var(--main-link-hover);

}

#pairing .recipe-picture:hover {
    border-color: var(--main-link-hover);
}

#pairing #ingredients {
    color: var(--main-light);
}

#pairing #ingredients hr{
    color: var(--main-light);
}

#pairing #ingredients ul{
    margin:0;
    text-indent: 0;
    list-style-type: "°";
}

#pairing #ingredients li{
    margin: 0;
    padding: 6px;
    text-indent: 5px;
    font-weight: normal;
}

#pairing #ingredients a{
    transition: 0.25s;
    color: var(--main-light);
}

#pairing #ingredients a:hover{
    color: var(--main-link-hover);
}

#pairing {
    transition: 1s;
    position: relative;
    top : -50px;
}

#pairing .pairing-card{
    left: 50%;
    transform: translateX(-50%);
}


@media (max-width: 1200px) {

    #pairing .pairing-description{
        width: unset;
    }

    #pairing .pairing-backdrop {
        top: -40px;
        /*height: 575px;*/


        width: 100%;

        object-fit: cover;
        position: absolute;
    }

    #pairing .backdrop-mask {
        /*height: 625px;*/
        /*width: 1200px;*/
        width: 100%;

        position: absolute;
        /*top : -625px;*/
        top: -50px;

        box-shadow: inset 0px -250px 30px -100px var(--main-background),
        inset 250px 0px 100px -100px var(--main-background),
        inset -250px 0px 100px -100px var(--main-background);
    }

    #pairing .backdrop-full {
        z-index: -1;
        width: 100%;
    }

    #pairing #pair-card{
        display: grid;
        gap: 0;
        grid-template-areas:
            "p r"
            "d d"

    }

    #pairing .m-poster{
        grid-area: p;
    }

    #pairing .movie-poster{
        width: 100px;
    }

    #pairing .pairing-description{
        grid-area: d;
        text-wrap: normal;
    }

    #pairing .r-picture{
        grid-area: r;
    }

    #pairing .recipe-picture{
        width: 150px;
    }


}

@media (max-width: 992px) {

}

@media (max-width: 768px) {

}

@media (max-width: 576px) {
    #pairing .pairing-description{
        font-size: 16px;
    }

    #pairing .recipe-picture{
        width: 100px;
    }
    #pairing .movie-poster{
        width: 66px;
    }

    #pairing h5 {
        font-size: 16px;
    }

    #pairing li{
        font-size: 14px;
    }

    #pairing .backdrop-mask{
        opacity: .85;
    }

    #pairing .ingredient-list{
        width: 75%;
    }

}
