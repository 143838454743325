#FAQ h2 {
    color: var(--main-light);
}

#FAQ div {
    color: var(--main-background);
}

#FAQ  a {
    transition: .25s;
    color: black;
}

#FAQ  a:hover {
    color: var(--main-link-hover);
}