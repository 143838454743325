#Footer {
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    background: grey;
    text-align: center;
    color: var(--main-light);
    background: var(--main-dark);
}

#Footer .content-wrap {
    box-sizing: border-box;
    width: 944px;
    flex-grow: 0;
    flex-shrink: 0;
}

#Footer .content-wrap li{
    padding: 0 0;
    margin: 0;
    display: inline;
    font-size: 18px;
}

#Footer .attribution {
    vertical-align: middle;
    display: inline-block;
}

#Footer a {
    transition: 0.25s;
    color: var(--main-light);
    text-decoration: none;
}

#Footer a:hover {
    color: var(--main-link-hover);
}

#Footer img:hover {
    transform: scale(1.05);
}

#Footer p {
    font-size: 14px;
    font-weight: normal;
}

#Footer .links{
    width: 50%;
}

#Footer a{
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
}

@media (max-width: 576px) {
    #Footer .content-wrap{
        width: 100%;
    }

    #Footer .links{
        width: 100%;
        display: grid;
        grid-template-areas:
            ". about faq git ."
            ". tmdb  . edam ."
    }

    #Footer .about{
        grid-area: about;
    }
    #Footer .faq{
        grid-area: faq;
    }
    #Footer .git{
        grid-area: git;
    }
    #Footer .tmdb{
        grid-area: tmdb;
    }
    #Footer .edam{
        grid-area: edam;
    }
}