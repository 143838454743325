#Pairings {
    color: #d3c4d3;
    background: none;
    max-width: 944px;
}

#Pairings .pair-title {
    transition: 0.25s;
    color: #f5f1f2;
}


#Pairings .pair-title:hover {
    color: var(--main-link-hover);
}

#Pairings #poster:hover{
    transition: 0.25s;
    border: 0.5px solid var(--main-link-hover);

}

#Pairings #recipe:hover {
    transition: 0.25s;
    border: 0.5px solid var(--main-link-hover);
}

/*Remove hr from last pairing*/
#Pairings .p-card:last-child hr {
    display: none;
}

#Pairings .description {
    font-weight: normal;
}

#Pairings .form-select, #Pairings option {
    background: #b6b0b0;
    color: var(--main-dark);
    border-color: var(--main-dark) ;
}



#Pairings .form-select{
    padding: 5px;
    text-align: center;
}

#Pairings .form-label{
    padding: 4px;
}

#Pairings #poster{
    width: 100px;
    height: 150px;
}

#Pairings #recipe{
    width: 150px;
    height: 150px;
}

#Pairings .card-content{
    gap: 1rem;
}

/*// Medium devices (tablets, 768px and up)*/
@media (max-width: 768px) {
    #Pairings .card-content{
        /*flex-direction: column;*/
        display: grid;
        grid-template-areas:
            "p ."
            "p r"
            "d d"
    }
    #Pairings #poster-container {
        order : -2;
        float: left;
    }
    #Pairings #recipe-container {
        order: -1;
        min-width: auto;
        float: right;
    }

    #Pairings .description {
        grid-area: d;
        margin: 0px;
    }

    #Pairings #poster{
        grid-area: p;
        width: 75px;
        height: 112px;
    }

    #Pairings #recipe{
        grid-area: r;
        width: 112px;
        height: 112px;
    }

    #Pairings h4{
    }

    #Pairings .card-content{
        gap : .25rem;
    }

    #Pairings label {
        /*display: inline;*/
    }


    #Pairings option{
    }

    #Pairings form{
        font-size: 14px;
    }
}