#About h2, #About p{
    color: var(--main-light);

}

#About p{
    font-weight: normal;
}

#About {
    max-width: 944px;
}